.header {
    top : 0;
    left: 0;
    width:100%;
    display: block;
    height:5rem;
    background-color: Orange;
    text-align: left;
    z-index: 100;
}

.logo-link {
    float:left;    
    text-align: center;
    padding:2px;    
}

img {
    width:90px;
    height:65px;
    padding-right:10px ;
}

.header ul {
    display: flex;
    justify-content: right;
    list-style: none;
    padding-right: 10rem;
    align-items: center ;
}

ul .btn {
    text-align: center;
    padding:0.5rem 1rem;
    font-weight :bold;
    z-index: 1000;
    font-family: "Abel",arial,sans-serif;
    font-size: 1em;
    color: white;
    text-transform: none;
}

.header .btn:hover {
    background-color:rgba(246, 247, 248, 0.3);  
    border-radius: 2px;
}
