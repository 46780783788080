.addbutton {
  height: 2.175rem;
  margin-left: 1rem !important;
  min-width: 2.575rem !important;
  max-width: 1.575rem !important;
}

.addItemButton {
  height: 2.175rem;
}

@media screen and (max-width: 1024px) {
  .responsive-modal {
    width: 82% !important;
  }
}

.button_link {
  text-decoration: none;
}

input {
  height: 1.075rem !important;
}

input[type='file'] {
  height: inherit !important;
}

.search-btn {
  margin: 20px 5px 1rem 5px !important;
  text-transform: none !important;
  height: 2.175rem !important;
  background-color: #f0ad4e !important;
}

.product-title {
  color: black;
  font-family: "Abel", Arial, sans-serif;
  font-size: 1.875rem;
  font-weight: normal;
  text-align: left;
  margin-left: 0.275rem;
}

.form-btn {
  margin: 1.275rem 0 1rem 0.5rem !important;
  text-transform: none !important;
  height: 2.175rem !important;
  cursor: pointer;
  user-select: none;
  color: rgb(255, 255, 255)
}

.form-label {
  padding-left: 7px;
  font-size: 15px;
  font-weight: bold;
  font-family: Arial, sans-serif;
}

.modaltitle {
  margin-left: 1rem;
}

.price_div {
  display: flex;
  align-items: center;
}

.buttondiv_productform {
  display: flex;
  float: right;
  margin-right: 15px;
  margin-bottom: 15px;
  margin-top: 20px;
  column-gap: 15px;
}

.product_modal_box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  background-color: white;
  border-radius: 10px !important;
  outline: 0;
}

.MuiModal-root > * {
  outline: 0;
}

.dashboard-btn {
  text-transform: none !important;
  margin: 15px 5px !important;
  font-size: 14px !important;
  font-weight: bold !important;
}

.upload-btn {
  display: inline-block !important;
  width: 7rem;
  text-align: center;
  box-sizing: border-box;
  border: 1px solid #3a3a3a;
  padding: 4px 10px !important;
  font-weight: 400 !important;
  vertical-align: middle;
  margin-top: 5px;
  background-color: #f4f3f3;
  color: black !important;
}